* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

html {
  scroll-behavior: smooth;
}

.App {
  overflow-x: hidden;
}

/* HEADER */

.header {
  background-color: #c98971;
  /* width: 100vw; */
  height: 800px;
  overflow-y: hidden;
}

/* NAV BAR */

.nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: rgb(255, 255, 255);
  z-index: 1000;
}
/* LOGO IMAGE */
.logo-box {
  margin-top: 7px;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  overflow: hidden;
}

.logo-box img {
  width: 100%;
  height: 100%;
  scale: 1.3;
}

/* MENU BAR */

.menu {
  width: 750px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 18px;
  font-weight: 400;
  text-transform: uppercase;
  z-index: 100;
}

.menu a {
  color: white;
  text-decoration: none;
}

/* logs */

.logs > i {
  font-size: 30px;
  cursor: pointer;
}

/*main*/

.main {
  margin-left: 100px;
  width: 90%;
  height: 600px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main-line {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  gap: 20px;
  height: 500px;
  width: 50%;
}

.greet {
  color: rgb(255, 255, 255);
  font-size: 28px;
  font-style: italic;
  text-transform: capitalize;
  font-weight: 500;
  word-spacing: 10px;
}

.headline {
  font-family: 'Dancing Script', cursive;
  font-size: 90px;
  font-weight: 600;
  color: white;
  /* -webkit-text-stroke: 1px rgb(84 11 70); */
  text-shadow: 0px 4px 4px #282828;
}

.headline-des {
  font-style: italic;
  color: rgb(255, 255, 255);
  color: rgb(29, 29, 29);
  font-size: 20px;
  font-weight: 400;
  color: white;
}
.pinky-lohar {
  font-family: 'Dancing Script', cursive;
  font-size: 43px;
  /* -webkit-text-stroke: 0.5px rgb(253, 253, 253); */
  text-shadow: 0px 4px 4px #282828;
  font-weight: 700;
  color: white;
}

.book {
  text-decoration: none;
  cursor: pointer;
  border: 2px solid white;
  padding: 10px 15px;
  text-transform: uppercase;
  color: rgb(29, 29, 29);
  color: white;
  transition: all 1s;
  background-color: #dd5e88;
}

.book:hover {
  background-color: white;
  color: rgb(29, 29, 29);
  box-shadow: 5px 5px 6px rgba(34, 34, 34, 0.911);
}

.headering {
  margin-top: 90px;
  width: 500px;
  height: 758px;
}

.headering img {
  filter: drop-shadow(-30px 0px 35px #3b3b3bdc);
  width: 100%;
  height: 100%;
}

/*Meadia Query */

/*Large Tablet*/

@media screen and (max-width: 1200px) {
  .headline {
    font-size: 70px;
  }

  .main {
    margin-left: 60px;
  }
}

/*Medium Tablet*/

@media screen and (max-width: 980px) {
  .menu {
    width: 650px;
  }

  .main {
    margin-left: 30px;
  }

  .greet span {
    display: block;
  }

  .main-line {
    gap: 10px;
  }

  .headline {
    font-size: 60px;
    line-height: 70px;
  }
}

/* Small or Vertical (side) table */

@media screen and (max-width: 820px) {
  .logo-box {
    width: 60px;
    height: 60px;
  }

  .logs > i {
    font-size: 25px;
  }
  .menu {
    width: 550px;
    font-size: 16px;
  }

  .main {
    flex-direction: column;
    position: relative;
    color: rgb(53, 53, 53);
  }

  .main-line {
    position: absolute;
    top: 51%;
    z-index: 1;
    align-items: center;
    text-align: justify;
    height: 400px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 430px;
  }

  .greet {
    position: absolute;
    top: -65%;
    left: -35%;
    /* width: 160px; */
    font-size: 22px;
    /* background-color: red; */
  }

  .greet span {
    font-size: 36px;
  }

  .headline {
    padding: 5px;
    font-size: 65px;
    line-height: 80px;
    background-color: rgba(63, 63, 63, 0.37);
    /* -webkit-text-stroke: 0px rgb(255, 255, 255); */
    color: white;
  }

  .headline-des {
    width: 380px;
    font-size: 16px;
    color: rgb(52, 52, 52);
    color: white;
  }

  .headering {
    margin-top: -2%;
    z-index: 0;
  }
}

@media screen and (max-width: 745px) {
  .logo-box {
    width: 50px;
    height: 50px;
  }

  .logs > i {
    font-size: 20px;
  }

  .menu {
    width: 500px;
    font-size: 14px;
  }

  .greet {
    top: -70%;
    left: -7%;
  }

  .headering {
    margin-top: 90px;
    width: 450px;
    height: 658px;
  }
}

/* Mobile */

@media screen and (max-width: 480px) {
  .logo-box {
    width: 50px;
    height: 50px;
  }

  .logs > i {
    font-size: 20px;
  }

  .menu {
    font-size: 14px;
  }

  .nav {
    margin: 5px auto;
    justify-content: space-between;
    margin: 0 8px;
  }

  .logo-box {
    width: 50px;
    height: 50px;
  }

  .menu {
    width: 340px;
    font-size: 10px;
    font-weight: 300;
  }

  .main-line {
    top: 62%;
    width: 360px;
    height: 330px;
  }

  .greet {
    left: 20%;
    top: -105%;
    font-size: 20px;
    text-align: center;
  }

  .pinky-lohar {
    display: inline;
  }

  .headline {
    font-size: 48px;
    line-height: 55px;
  }

  .headline-des {
    font-size: 16px;
    font-weight: 400;
    width: 300px;
    color: white;
  }

  .book {
    width: 240px;
    height: 40px;
    padding: 5px 10px;
  }

  .headering {
    margin-top: 130px;
    width: 410px;
    height: 618px;
  }
}

@media screen and (max-width: 424px) {
  .headering {
    width: 390px;
  }
}

@media screen and (max-width: 407px) {
  .headering {
    margin-top: 135px;
    width: 380px;
  }
}

/* Mobile Small Screen */

@media screen and (max-width: 360px) {
  .menu {
    width: 300px;
    font-size: 8px;
  }

  .main-line {
    width: 340px;
    /* height: 330px; */
  }

  /* .greet {
    left: 2%;
    width: 130px;
    font-size: 18px;
  } */

  /* .pinky-lohar {
    display: block;
    font-size: 20px;
  } */

  .headline {
    width: 280px;
    font-size: 40px;
    text-align: center;
  }

  .headline-des {
    font-size: 16px;
    font-weight: 400;
    width: 250px;
  }
}

/*TESTIMONIALS SECTION*/

.testimonials {
  display: flex;
  flex-direction: column;
  gap: 50px;
  background-color: #253047;
  padding: 20px 0;
}

.testimonials-heading {
  text-align: center;
}

.testimonials-heading h1 {
  font-family: 'Ultra', serif;
  font-family: 'Dancing Script', cursive;
  font-weight: 600;
  font-style: normal;
  color: white;
  text-transform: capitalize;
  font-size: 100px;
  display: inline-block;
  border-bottom: 3px solid white;
}

.testimonials-container {
  padding: 10px;
  display: flex;
  /* gap: 5%; */
  margin-bottom: 50px;
  justify-content: center;
  align-items: center;
  /* position: relative; */
}

.testimonials-slider {
  padding: 10px;
  /* width: 100%; Change width to 100% */
  /* max-width: 1000px; Set maximum width */
  /* height: 550px; */
  /* position: relative; */
  /* overflow: hidden; */

  /* without crausel */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 40px;
}

.testimonials-card {
  /* position: absolute; */
  /* margin-left: 2px;
  top: 20%; */
  background-color: white;
  width: 300px;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 8px 10px 10px 2px rgb(0, 0, 0, 25%);
  transition: all 1.5s ease;

  /* without crausel */
  margin-top: 60px;
  position: relative;
}

.testimonials-card:hover,
.testimonials-card:hover .testimonials-pic {
  box-shadow: 8px 10px 10px 2px rgb(0, 0, 0, 40%);
}

.testimonials-card:hover .testimonials-pic img {
  scale: 1.7;
}

.testimonials-pic {
  width: 180px;
  height: 180px;
  border: 6px solid white;
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  transform: translateY(-50%);
  box-shadow: 8px 10px 10px 2px rgba(0, 0, 0, 25%);
  transition: all 1s;
}

.testimonials-pic img {
  width: 100%;
  height: 100%;
  transition: 0.5s;
  scale: 1.4;
  object-fit: cover;
}

.testimonials-name {
  text-transform: uppercase;
  margin-top: 107px;
  color: #80acbb;
}

/* RATING  */

.rating {
  display: flex;
  gap: 3px;
  color: #ffc700;
  margin-bottom: 5px;
}

/* testimonials rating updated style */

.rating > i:first-child,
.rating > i:last-child {
  font-size: 20px;
  margin-top: 20px;
}

.rating > i:nth-child(even) {
  font-size: 28px;
  margin-top: 12px;
}

.rating > i:nth-child(3) {
  font-size: 35px;
  margin-top: 3px;
}

.testimonials-sub {
  text-transform: uppercase;
  color: #424242;
  /* color: white; */
  font-size: 18px;
}

.testimonials-review {
  width: 85%;
  margin-bottom: 40px;
  height: 150px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.testimonials-review p {
  color: #767676;
  text-align: justify;
  font-size: 16px;
  font-weight: 700;
}

.testimonials-qoute > img {
  position: absolute;
  bottom: -15%;
  scale: 0.6;
}

/* style crausel */

/* .testimonials-qoute > img {
  position: absolute;
  bottom: -15%;
  top: 0;
  right: 0;
  scale: 0.6;
} */

/* .testimonials-left-arrow {
  font-size: 120px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 3%;
  z-index: 1;
  color: white;
  height: 70px;
  overflow: hidden;
  cursor: pointer;

  text-shadow: 3px 3px 5px black;
} */

/* .testimonials-right-arrow {
  font-size: 120px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 3%;
  z-index: 1;
  color: white;
  height: 70px;
  overflow: hidden;
  cursor: pointer;
  text-shadow: -3px 3px 5px black;
} */

/*///// TESTIMONIALS MEDIA QUERY //////*/

/*Large Tablet */

/* @media screen and (max-width: 1200px) {
  .testimonials-slider {
    max-width: 1000px;
  }

  .testimonials-card {
    top: 15%;
    width: 300px;
  }

  .testimonials-pic {
    width: 150px;
    height: 150px;
  }

  .testimonials-qoute > img {
    bottom: -15%;
    right: -5%;
    scale: 0.5;
  }
} */

@media screen and (max-width: 1020px) {
  .testimonials-card:last-child {
    margin-top: 100px;
  }
}

/* @media screen and (max-width: 768px) { */
/* .testimonials-heading > h1 {
    font-size: 70px;
  } */

/* .testimonials-slider {
    max-width: 570px;
  } */

/* .testimonials-card:nth-child(2) {
    margin-top: 100px;
  } */

/* .testimonials-card {
    margin-left: 15px;
  } */
/* } */

@media screen and (max-width: 680px) {
  .testimonials-card:nth-child(2) {
    margin-top: 100px;
  }
}

@media screen and (max-width: 564px) {
  .testimonials-heading > h1 {
    font-size: 58px;
  }

  .testimonials-card {
    width: 270px;
    height: 345px;
    margin-top: 40px;
  }

  .testimonials-card:last-child,
  .testimonials-card:nth-child(2) {
    margin-top: 84px;
  }

  .testimonials-pic {
    width: 160px;
    height: 160px;
  }

  .testimonials-name {
    margin-top: 100px;
  }
}

@media screen and (max-width: 360px) {
  .testimonials-heading > h1 {
    font-size: 50px;
  }
}

/* SERVICES SECTION */

.services-sec {
  scroll-behavior: smooth;
  background-color: rgb(235, 235, 235);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px 0;
  position: relative;
}

.services-head {
  /* background-color: red; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  position: relative;
}

.services-heading {
  font-family: 'Dancing Script', cursive;
  font-weight: 600;
  font-style: normal;
  padding-left: 150px;
  padding-right: 70px;
  padding-bottom: 25px;
  background-color: #c98971;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  color: white;
  text-transform: capitalize;
  font-size: 120px;
  text-shadow: 4px 10px 7px rgba(39, 39, 39, 0.445);
}

.services-head > p,
.services-line {
  font-family: 'Dancing Script', cursive;
  font-size: 34px;
  color: #dd5e88;
  font-weight: 700;
  border-bottom: 2px solid #dd5e88;
}
.services-line {
  width: 400px;
  margin: 0 auto;
  display: none;
}

.lady {
  /* position: absolute;
  top: 0;
  right: 0; */
  width: 210px;
  z-index: 1;
}
.lady > img {
  width: 100%;
  height: 100%;
}

.services-outer-container {
  width: 98%;
  width: 100%;
  padding: 30px 50px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.services-card {
  transform-style: preserve-3d;
  width: 280px;
  height: 418px;
  border-radius: 10px;
  transition: all 1.5s;
  display: flex;
  align-items: flex-end;
  position: relative;
  box-shadow: 0px 5px 7px 3px rgba(36, 36, 36, 0.555);
  margin-top: 10px;
  margin-bottom: 10px;
}

.services-img {
  width: 280px;
  height: 418px;
  border-radius: 10px;
  overflow: hidden;
}

.services-img > img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.services-card > h2 {
  /* font-family: 'Dancing Script', cursive; */
  /* font-size: 36px; */
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 150px;
  transform: translateZ(30px);
  text-align: center;
  text-transform: capitalize;
  background-color: #dd5e88;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  color: white;
  letter-spacing: 3px;

  /*for no back content style */
  font-size: 24px;
  font-weight: 200;
  display: flex;
  justify-content: center;
  align-items: center;
}

.services-container:hover .services-card {
  transform: rotateY(180deg);
}

.services-card-details {
  background-color: #253047;
  width: 280px;
  height: 418px;
  border-radius: 10px;
  position: absolute;
  top: 0;
  transform: rotateY(180deg);
  backface-visibility: hidden;
  color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5%;
  padding-top: 15%;

  /*for no back content style */
  justify-content: center;
}

.services-card-details-heading > h2 {
  font-family: 'Dancing Script', cursive;
  font-size: 40px;
  text-transform: capitalize;
  color: #dd5e88;
  /* padding: 5px 20px; */
  border-radius: 10px;

  /*for no back content style */
  padding: 5px 5px;
  text-align: center;
}

.services-details {
  font-size: 18px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.tr {
  width: 270px;
  border: 1px solid transparent;
  border-radius: 10px;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  cursor: pointer;
  transition: all 1.5s ease;
}

.tr:hover {
  border: 0.5px solid white;
  box-shadow: 2px 2px 5px #222222d2;
  background-color: #dd5e88;
}

.tr:hover .font {
  font-family: 'Dancing Script', cursive;
  font-size: 23px;
}

/* Dots */
.dots {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.dot {
  width: 15px;
  height: 15px;
  border-radius: 10px;
  background-color: #1a1a1a;
  cursor: pointer;
}

/* MEDIA QUERY */

@media screen and (max-width: 1020px) {
  .services-heading {
    font-size: 100px;
  }
}
@media screen and (max-width: 850px) {
  .services-heading {
    font-size: 90px;
    padding-left: 100px;
  }

  .services-head > p {
    font-size: 26px;
  }
}
@media screen and (max-width: 720px) {
  .services-heading {
    font-size: 80px;
    padding-left: 60px;
    padding-right: 40px;
  }

  .services-head > p {
    font-size: 22px;
  }
}
@media screen and (max-width: 600px) {
  .lady {
    width: 150px;
    z-index: 1;
  }

  .services-head > p {
    display: none;
  }

  .services-line {
    display: block;
  }
}

@media screen and (max-width: 480px) {
  .services-heading {
    font-size: 70px;
    padding-left: 60px;
    padding-right: 40px;
  }
  .services-line {
    font-size: 26px;
    width: 300px;
  }
}

@media screen and (max-width: 420px) {
  .services-heading {
    font-size: 60px;
    padding-left: 50px;
    padding-right: 30px;
  }

  .lady {
    width: 120px;
  }

  .services-line {
    font-size: 26px;
    width: 300px;
  }
}

/*///////// ABOUT SECTION //////////////*/

.about-section {
  display: flex;
  gap: 15%;
  position: relative;
}

.about-me {
  background-color: #253047;
  font-size: 64px;
  color: white;
  display: flex;
  align-items: center;
  gap: 50px;
  font-weight: 300;
  width: 70%;
  padding: 15px 0;
  padding-left: 100px;
}

.profile-pic {
  width: 300px;
  height: 390px;
  overflow: hidden;
  position: absolute;
  left: 26%;
  top: 11%;
  background-color: white;
  z-index: 1;
  padding: 15px;
}

.border {
  position: absolute;
  right: 0;
  top: 0;
  width: 150px;
  height: 390px;
  background-color: #253047;
  z-index: -1;
}

.profile-pic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.about-me-des {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.about-me-des p:first-child {
  font-family: 'Dancing Script', cursive;
  font-size: 55px;
  /* margin-top: 10px; */
}

.about-me-des p:last-child {
  /* margin-top: 10px; */
  padding-right: 30px;
  text-align: justify;
  font-style: italic;
  font-size: 15px;
}

.about-me-des p:last-child > span {
  background-color: #c98971;
}

.media-profile-pic {
  display: none;
}

/*/// media ///*/

@media screen and (max-width: 1200px) {
  .profile-pic {
    width: 280px;
  }
  .border {
    width: 130px;
  }
}
@media screen and (max-width: 1020px) {
  .about-me {
    padding-left: 70px;
  }

  .profile-pic {
    width: 250px;
  }

  .border {
    width: 125px;
  }
}

@media screen and (max-width: 900px) {
  .about-section {
    flex-direction: column;
  }

  .about-me {
    width: 100%;
    font-weight: 400;
    padding: 15px 10px;
    justify-content: center;
    gap: 30px;
    font-size: 60px;
  }

  .media-profile-pic {
    display: block;
    width: 210px;
    height: 210px;
    border: 10px solid white;
  }
  .media-profile-pic > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .about {
    display: flex;
    padding-left: 0px;
  }

  .ME {
    display: flex;
  }

  .profile-pic {
    display: none;
  }

  .about-me-des {
    align-items: center;
    padding: 0 15px;
  }

  .about-me-des p:last-child {
    padding-right: 0px;
  }
}

@media screen and (max-width: 560px) {
  .about-me {
    font-weight: 400;
    gap: 25px;
    font-size: 50px;
  }

  .media-profile-pic {
    width: 180px;
    height: 180px;
  }
}

@media screen and (max-width: 500px) {
  .about-me {
    font-size: 40px;
  }

  .media-profile-pic {
    width: 150px;
    height: 150px;
  }
}

@media screen and (max-width: 400px) {
  .about-me {
    font-size: 35px;
  }

  .media-profile-pic {
    width: 130px;
    height: 130px;
  }
}

/*///// MILESTONE /////*/

/* .milestone-sec {
  background-color: #ebebeb;
} */

.milestone-heading > h2 {
  text-align: center;
  font-size: 80px;
  text-transform: uppercase;
  font-weight: 200;
  padding: 40px 0;
  color: #c98971;
}

/*/// common  //*/
.mile-box {
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0;
  margin-bottom: 35px;
  background-color: white;
  padding-top: 15px;
}

.center-design {
  width: 900px;
  margin: 0 15px;
}

.mile-pic {
  width: 400px;
  height: 400px;
  box-shadow: 1px 5px 15px 4px rgb(63, 63, 63);
  border-radius: 15px;
  float: left;
  margin-right: 20px;
  overflow: hidden;
  margin-bottom: 1px;
}

.mile-pic > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.mile-bullet {
  font-size: 55px;
  text-transform: capitalize;
  font-family: 'Dancing Script', cursive;
  font-weight: 700;
  margin-bottom: 10px;
  /* text-align: left; */
}

.mile-content {
  padding: 10px;
}

.mile-content p {
  text-align: justify;
  font-size: 22px;
}

.mile-content-color h3 {
  color: cornsilk;
  color: black;
}
.mile-content-color p {
  color: white;
  color: black;
  color: #333333;
}

/* /// media query ////*/

@media screen and (max-width: 900px) {
  .mile-pic {
    width: 300px;
    height: 300px;
  }

  .mile-bullet {
    font-size: 55px;
  }

  .mile-content p {
    font-size: 20px;
  }
}

@media screen and (max-width: 704px) {
  .center-design {
    margin: 0 15px;
  }

  .mile-pic {
    width: 300px;
    height: 300px;
  }

  .mile-bullet {
    font-size: 45px;
  }

  .mile-content p {
    font-size: 18px;
  }
}
@media screen and (max-width: 640px) {
  .milestone-heading > h2 {
    font-size: 70px;
  }

  .mile-pic {
    width: 250px;
    height: 250px;
  }

  .mile-bullet {
    font-size: 35px;
  }
  .mile-content p {
    font-size: 16px;
  }
}
@media screen and (max-width: 530px) {
  .mile-pic {
    width: 230px;
    height: 230px;
  }

  .mile-bullet {
    font-size: 30px;
  }
  .mile-content p {
    font-size: 16px;
  }
}
@media screen and (max-width: 477px) {
  .milestone-heading > h2 {
    padding: 20px 0;
  }

  .milestone-heading > h2 {
    font-size: 60px;
  }

  .mile-bullet {
    font-size: 26px;
  }
  .mile-content p {
    font-size: 15px;
    text-align: justify;
  }
}

@media screen and (max-width: 450px) {
  .mile-pic {
    width: 200px;
    height: 200px;
  }

  .mile-bullet {
    font-size: 24px;
  }

  .mile-content p {
    font-size: 14px;
  }
}

@media screen and (max-width: 410px) {
  .mile-pic {
    width: 170px;
    height: 170px;
  }
}
@media screen and (max-width: 378px) {
  .milestone-heading > h2 {
    font-size: 50px;
  }

  .mile-pic {
    width: 150px;
    height: 150px;
  }

  .mile-bullet {
    font-size: 22px;
  }
}

/*///////// STATISTICS ////////////////*/
.statistics-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
}

.experience,
.reviews,
.customers {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.experience > h2,
.reviews > h2,
.customers > h2 {
  font-size: 46px;
  color: #1e1e1e;
}

.statistics-title {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  font-size: 28px;
  text-transform: uppercase;
  color: #585858;
}

/* ////MEDIA QUERY //// */

@media screen and (max-width: 1020px) {
  .experience > h2,
  .reviews > h2,
  .customers > h2 {
    font-size: 40px;
  }

  .statistics-title {
    font-size: 24px;
  }
}

@media screen and (max-width: 820px) {
  .experience > h2,
  .reviews > h2,
  .customers > h2 {
    font-size: 30px;
  }

  .statistics-title {
    font-size: 20px;
  }
}
@media screen and (max-width: 680px) {
  .experience > h2,
  .reviews > h2,
  .customers > h2 {
    font-size: 28px;
  }

  .statistics-title {
    font-size: 18px;
  }
}

@media screen and (max-width: 610px) {
  .experience > h2,
  .reviews > h2,
  .customers > h2 {
    font-size: 24px;
  }

  .statistics-title {
    font-size: 16px;
  }
}

@media screen and (max-width: 550px) {
  .statistics-bar {
    padding: 0 15px;
  }

  .experience > h2,
  .reviews > h2,
  .customers > h2 {
    font-size: 22px;
  }

  .statistics-title {
    font-size: 14px;
  }
}
@media screen and (max-width: 480px) {
  .experience > h2,
  .reviews > h2,
  .customers > h2 {
    font-size: 20px;
  }

  .statistics-title {
    font-size: 12px;
  }
}

@media screen and (max-width: 400px) {
  .statistics-bar {
    padding: 0 10px;
  }

  .experience > h2,
  .reviews > h2,
  .customers > h2 {
    font-size: 18px;
  }

  .statistics-title {
    font-size: 10px;
  }
}

/*///////// CONTACT US ///////////////*/

.contact-us {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: rgb(235, 235, 235); */
  padding: 40px 0;
  background-color: #ebebeb;
}

.contact {
  background-color: #253047;
  width: 55%;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-top: 15px;
  padding-left: 40px;
  padding-bottom: 20px;
}

.contact > h2 {
  font-size: 60px;
  color: #b5b5b5;
  text-transform: uppercase;
  font-weight: 200;
  text-transform: uppercase;
  margin-bottom: 5px;
}

form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

form > label {
  color: #b5b5b5;
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 200;
}

form > input {
  width: 350px;
  border-radius: 25px;
  font-size: 18px;
  border: none;
  padding: 4px 10px;
  margin-bottom: 15px;
  background-color: #d9d9d9;
}

form > input::placeholder {
  color: #9e9e9e;
}

form > input:focus {
  outline: 3px solid #c98971;
}

textarea {
  width: 350px;
  height: 170px;
  border-radius: 25px;
  padding: 4px 10px;
  font-size: 20px;
  border: none;
  background-color: #d9d9d9;
}

textarea:focus {
  outline: 3px solid #c98971;
}

form > input[type='submit'] {
  margin-top: 50px;
  background-color: #c98971;
  color: white;
  cursor: pointer;
  font-size: 22px;
}

textarea::-webkit-scrollbar {
  width: 10px;
}
textarea::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}

textarea::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* get in touch */

.get-in-touch {
  background-color: #c88873;
  width: 35%;
  margin-left: -170px;
}

.get-in-touch h2:first-child {
  font-size: 45px;
  color: #ffffff;
  text-transform: uppercase;
  text-align: center;
  font-weight: 200;
  text-transform: uppercase;
}

.svgimg {
  margin: 0 auto;
  width: 85%;
}

.svgimg > img {
  width: 100%;
}

.info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-info:first-child {
  margin-top: 0;
}

.contact-info {
  width: 400px;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 5px;
}

.contact-info > i {
  font-size: 28px;
  color: #253047;
  width: 35px;
  text-align: center;
}

.contact-info a {
  text-decoration: none;
  font-size: 22px;
  font-weight: 200;
  color: white;
}

.social-icons {
  size: 70px;
}

.contact-social {
  font-size: 45px;
  color: #ffffff;
  text-transform: uppercase;
  text-align: center;
  font-weight: 200;
  margin-top: 5px;
}

.contact-social-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  padding-bottom: 15px;
}

.contact-social-icons a > i {
  font-size: 45px;
  color: #253047;
  cursor: pointer;
  transition: 0.5s;
}

.contact-social-icons a > i:hover {
  color: white;
}

/*/// MEDIA QUERY ///*/

@media screen and (max-width: 1050px) {
  .contact {
    width: 550px;
  }

  .get-in-touch {
    width: 350px;
    margin-left: -80px;
  }
}

@media screen and (max-width: 820px) {
  .contact {
    width: 500px;
    margin-left: 20px;
    margin-right: 20px;
  }

  .contact > h2 {
    font-size: 50px;
  }

  form > label {
    font-size: 22px;
  }

  form > input {
    width: 300px;
    font-size: 16px;
  }

  textarea {
    width: 300px;
    height: 150px;
    font-size: 16px;
  }

  .get-in-touch {
    width: 300px;
    margin-left: -100px;
  }

  .get-in-touch h2:first-child {
    font-size: 35px;
  }

  .svgimg {
    width: 65%;
  }

  .contact-social {
    font-size: 30px;
  }
}

@media screen and (max-width: 740px) {
  .contact-us {
    flex-direction: column;
  }

  .contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 0px;
  }

  .get-in-touch {
    background-color: #c88873;
    width: 500px;
    margin-left: 0px;
  }

  .get-in-touch h2:first-child {
    font-size: 40px;
  }

  .svgimg {
    width: 250px;
  }
}

@media screen and (max-width: 500px) {
  .contact {
    width: 400px;
  }

  .get-in-touch {
    width: 400px;
  }
}

@media screen and (max-width: 400px) {
  .contact {
    width: 300px;
  }

  .contact > h2 {
    font-size: 40px;
  }

  form > label {
    font-size: 20px;
  }

  form > input {
    width: 250px;
    font-size: 16px;
  }

  textarea {
    width: 250px;
    height: 150px;
    font-size: 16px;
  }

  .get-in-touch {
    width: 300px;
  }

  .get-in-touch h2:first-child {
    font-size: 30px;
  }

  .svgimg {
    width: 60%;
  }

  .contact-social {
    font-size: 26px;
  }
}

/*//// FOOTER ////*/

.footer {
  background-color: #253047;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.footer-upper {
  display: flex;
  justify-content: space-around;
  padding-top: 20px;
}

/* pinky lohar */

.footer-logo {
  width: 200px;
  height: 70px;
  border-radius: 20px;
  overflow: hidden;
  background-color: yellow;
  margin-bottom: 20px;
  margin-top: 50px;
  cursor: pointer;
  scroll-behavior: smooth;
}

.footer-logo > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.footer-info {
  display: flex;
  align-items: center;
  margin-top: 10px;
  color: white;
  font-size: 18px;
  font-weight: 200;
}

.footer-info > i {
  font-size: 30px;
  width: 40px;
  text-align: center;
}

.footer-info > a {
  color: white;
  font-size: 18px;
  font-weight: 200;
  text-decoration: none;
}

.footer-upper h3 {
  font-size: 30px;
  font-weight: 400;
  text-transform: uppercase;
  color: white;
  margin-top: 15px;
  margin-bottom: 10px;
}

/* company */

.company > a {
  display: block;
  text-decoration: none;
  color: white;
  font-size: 18px;
  font-weight: 200;
  margin-top: 5px;
  text-transform: capitalize;
}

/* follow */

.footer-social {
  display: flex;
  justify-content: center;
}

.footer-social a > i {
  font-size: 45px;
  color: white;
  cursor: pointer;
  transition: 0.5s;
}
.footer-social a > i:hover {
  color: #c98971;
}

/* subscribe */

.subscribe {
  display: flex;
  flex-direction: column;
}

.subscribe p {
  color: white;
  font-size: 14px;
  margin-bottom: 15px;
  width: 300px;
}

.subscribe-bar {
  background-color: #6e798d;
  display: flex;
  justify-content: space-between;
  padding: 8px 12px;
  border-radius: 25px;
}

.subscribe-bar > input {
  font-size: 15px;
  border: none;
  background-color: #6e798d;
  color: white;
}
.subscribe-bar > input::placeholder {
  font-size: 15px;
  color: white;
}

.subscribe-bar > input:focus {
  outline: none;
}

.subscribe-bar button {
  padding: 5px 10px;
  border-radius: 20px;
  border: none;
  background-color: #c88873;
  color: white;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.footer-lower p {
  text-align: center;
  color: white;
  font-size: 18px;
  margin-bottom: 10px;
}

/* MEDIA QUERY */

@media screen and (max-width: 900px) {
  .footer-logo {
    width: 150px;
    height: 60px;
    margin-top: 30px;
  }

  .footer-upper h3 {
    font-size: 24px;
  }
}

@media screen and (max-width: 770px) {
  .footer {
    gap: 30px;
  }

  .footer-logo {
    width: 120px;
    height: 50px;
    margin-top: 20px;
  }

  .footer-upper h3 {
    font-size: 20px;
  }

  .subscribe-bar {
    background-color: #6e798d;
    display: flex;
    justify-content: space-between;
    padding: 8px 12px;
    border-radius: 25px;
  }

  .subscribe-bar > input {
    width: 130px;
  }
}

@media screen and (max-width: 650px) {
  .footer {
    gap: 30px;
  }

  .footer-upper {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: space-around; */
    padding-top: 10px;
  }

  .footer-logo {
    width: 300px;
    height: 90px;
    margin-top: 20px;
  }

  .footer-upper h3 {
    font-size: 26px;
    text-align: center;
  }

  .company > a {
    display: inline-block;
    margin-left: 15px;
    text-align: center;
  }

  .subscribe p {
    text-align: center;
  }

  .subscribe-bar > input {
    width: 150px;
  }

  .footer-lower p {
    font-size: 14px;
  }
}
@media screen and (max-width: 555px) {
  .footer-logo {
    width: 250px;
    height: 80px;
    margin-top: 20px;
  }

  .footer-upper h3 {
    font-size: 24px;
    text-align: center;
  }

  .company > a {
    margin-left: 10px;
    font-size: 14px;
  }

  .subscribe p {
    font-size: 12px;
  }

  .footer-lower p {
    font-size: 10px;
  }
}
@media screen and (max-width: 430px) {
  .footer-logo {
    width: 220px;
    height: 70px;
    margin-top: 20px;
  }

  .footer-upper h3 {
    font-size: 22px;
    text-align: center;
  }

  .company > a {
    margin-left: 8px;
    font-size: 12px;
  }

  .subscribe p {
    font-size: 10px;
  }

  .footer-lower p {
    font-size: 10px;
  }
}

/* ////// SCROLL TOP ////// */

.scroll-top {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  font-weight: 600;
  color: white;

  background-color: #c88873;
  text-decoration: none;

  position: fixed;
  transform: rotate(270deg);
  bottom: 30px;
  right: 30px;

  z-index: 1000;
}

/* media */

@media screen and (max-width: 500px) {
  .scroll-top {
    width: 40px;
    height: 40px;
    font-size: 30px;
    right: 10px;
    bottom: 27px;
  }
}

/* LOGIN */
.login-us {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(235, 235, 235);
  padding: 40px 0;
}

.login {
  background-color: #253047;
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login > h2 {
  font-size: 60px;
  color: #b5b5b5;
  text-transform: uppercase;
  font-weight: 200;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.form > label {
  color: #b5b5b5;
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 200;
}

.form > input {
  width: 350px;
  border-radius: 25px;
  font-size: 18px;
  border: none;
  padding: 4px 10px;
  margin-bottom: 15px;
  background-color: #d9d9d9;
}

.form > input::placeholder {
  color: #9e9e9e;
}

form > input:focus {
  outline: 3px solid #c98971;
}

/* textarea {
  width: 350px;
  height: 170px;
  border-radius: 25px;
  padding: 4px 10px;
  font-size: 20px;
  border: none;
  background-color: #d9d9d9;
} */

textarea:focus {
  outline: 3px solid #c98971;
}

.form .login-submit {
  border: none;
  margin-top: 30px;
  padding: 5px;
  background-color: #c98971;
  color: white;
  width: 40%;
  cursor: pointer;
  font-size: 22px;
}
.form .login-submit:hover {
  border: none;
  margin-top: 30px;
  padding: 5px;
  width: 40%;
  background-color: #c98971;
  opacity: 0.9;
  color: white;
  cursor: pointer;
  font-size: 22px;
}
.form .error {
  margin-top: -5px;
  color: #eb5f5f;
  font-size: 12px;
}
.login .permission-toast {
  color: yellow;
  font-size: 12px;
  word-wrap: break-word;
  text-overflow: ellipsis;
  width: 75%;
}
